import React from 'react';

const Lore = () => {
    return (
        <>
            <p>
                <i>“One fine day I woke up and wanted to put 10,000 frogs on the Bitcoin blockchain at all costs.”</i>
            </p>
            <p>
                — Frogtoshi Nakamoto
            </p>
            <br/>
            <br/>
            <p>
                These are 10,000 pure digital collectibles that will remain on Bitcoin forever. No more will ever be created.
            </p>
            <p>
                Rarities of all traits within each layer are equal, allowing subjective appreciation of aesthetics and satoshi-based rarities to emerge.
            </p>
        </>
    )
}

export default Lore